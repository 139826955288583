<template>
  <div class="ticket-available" v-if="!isProcessing">
    <template v-if="availableTicketsSummary.length > 0">
      <p class="ticket-available__explain">有効期限の近い回数券より自動に使用されます。</p>
      <ticket-expired class="ticket-available__item" v-for="(availableTicketSummary, index) in availableTicketsSummary" :key="index" :available-ticket-summary="availableTicketSummary" />
    </template>
    <p class="ticket-available__warning" v-else>ご利用可能な回数券がありません</p>
  </div>
</template>

<script>
import TicketExpired from '@/components/ticket/expired'

export default {
  components: { TicketExpired },
  name: 'ticket_available',
  async mounted () {
    // 回数券がなければ404へ
    if (!this.tickets) {
      this.$router.push({ name: 'notfound' })
      return
    }

    // 発行中の回数券情報の取得
    // それでもない場合は404へ
    const stid = Object.values(this.tickets)[0].stid
    if (!this.settingTicket) await this.$store.dispatch('settingTickets/getSettingTicket', stid)
    if (!this.settingTicket) {
      this.$router.push({ name: 'notfound' })
      return
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} 発行済み回数券情報
     */
    tickets () {
      return this.$store.getters['tickets/tickets']
    },
    /**
     * @return {Object} 現在の発行中の回数券情報
     */
    settingTicket () {
      return this.$store.getters['settingTickets/settingTicket']
    },
    /**
     * @return {Object[]} 終了日時毎にまとめられた利用可能な回数券のサマリ
     * [{ endAt: 終了日時, ticketCount: 利用可能枚数 }, {...}] indexが若い順に終了日時が早い
     */
    availableTicketsSummary () {
      const originAvailableTickets = this.$store.getters['tickets/availableTickets']
      if (originAvailableTickets.length === 0) {
        return []
      }
      return this.createAvailableTicketsSummary(originAvailableTickets)
    }
  },
  methods: {
    /**
     * 利用可能な回数券を終了日時ごとにまとめる
     *
     * @param {Object[]} availableTickets 利用可能な回数券一覧
     * @return {Object[]} 終了日時毎にまとめられた利用可能な回数券のサマリ [{ endAt: 有効期限の日時(Date), availableCount: 利用可能枚数(Number) }, {...}]
     */
    createAvailableTicketsSummary (availableTickets) {
      const availableTicketsSummary = []
      availableTickets.forEach(availableTicket => {
        const availableTicketEndAt = availableTicket.endAt.toDate()
        const summaryIndex = availableTicketsSummary.findIndex(summary => summary.endAt.getTime() === availableTicketEndAt.getTime())
        if (summaryIndex >= 0) {
          availableTicketsSummary[summaryIndex].availableCount++
        } else {
          availableTicketsSummary.push({
            endAt: availableTicketEndAt,
            availableCount: 1
          })
        }
      })
      return availableTicketsSummary
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket-available {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__explain {
    margin: 0;
    font-size: 1.2rem;
  }
  &__item {
    margin-top: $unit_size*2;
  }
  &__warning {
    margin: 0;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
