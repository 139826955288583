<template>
  <div class="ticket-expired">
    <div class="ticket-expired__item">
      <p class="ticket-expired__item__text">有効期限：{{endAt}}</p>
      <p class="ticket-expired__item__text"><span class="ticket-expired__item__text__strong">{{availableCount}}</span>枚</p>
    </div>
  </div>
</template>

<script>
import moment from '@/assets/lib/moment'

export default {
  mixins: [moment],
  props: {
    // 利用可能回数券の概要
    // { endAt: 有効期限の日時(Date), availableCount: 利用可能枚数(Number) }
    availableTicketSummary: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} 有効期限の日時表記
     */
    endAt () {
      return this.formatted(this.availableTicketSummary.endAt, 'YYYY/MM/DD HH:mm')
    },
    /**
     * @return {Number} 利用可能枚数
     */
    availableCount () {
      return this.availableTicketSummary.availableCount
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket-expired {
  font-size: 1.4rem;
  text-align: left;
  &__item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    max-width: $max_width;
    padding: $unit_size*2 $padding_width;
    margin-left: -$padding_width;
    border-bottom: 1px solid $gray_color;
    &__text {
      margin: 0;
      &__strong {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }
}
</style>
